import React from 'react';
import GatsbyImage from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import { RECIPES_ROUTE } from '../constants';

export default ({ data: { allBlog: { edges: blogs } } }) => {
    return (
        <Layout pageTitle="Farm Fresh Recipes" ogUrl="/recipes/">
          <>
            <div className='green-heading text-center'>
              <h2>{`FARM FRESH RECIPES`}</h2>
            </div>
            <div className='layout-container'>
              <div className='recipe-list-container'>
                <div className='grid-container add-width-2 margin-top-4'>
                  {blogs.map(({node}, id) => {
                    if (node.title === "TEST BLOG - DO NOT DELETE YET") {
                      return null
                    }
                    return (
                      <div className='grid-item grid-item-3 add-padding-2' key={id}>
                        <Link to={`${RECIPES_ROUTE}${node.path}`} className='big-link-container'>
                          <div>
                            <GatsbyImage fluid={{ ...node.blogImage.childImageSharp.fluid, aspectRatio: 5/4 }} style={{height: "100%"}} />
                          </div>
                          <div className='content with-footer'>
                            <h5>{node.title}</h5>
                            <div className='footer'>
                              <span className='text-muted'>{node.blogHeaderDate}</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </>
        </Layout>
    )
}

export const query = graphql`
query BlogQuery {
    allBlog(sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          id
          title
          path
          blogHeaderDate
          blogImage {
            childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }
  }
`